
export default {
    Home: {
        // 导航栏
        Nav: {
            Home: "首頁",
            Contact: '聯係',
        },
        // index-block1
        Block1: {
            Title: '與我們聯係，建立您的社交媒體營銷',
            Dec: '擁有强大的在綫形象和社交策略至關重要，通過與我們合作，我們可以為您提供提高在綫知名度、脫穎而出和發展業務所需的工具。',
            Button: '聯係我們'
        },
        // index-block2
        Block2: {
            Title: '我們提供解決方案來發展您的業務',
            Dec: '如何您目前沒有社交活動，目前在内部管理您的業務或正在尋找一個團隊來接管在綫營銷，我們爲您提供解決方案。',
            // grids
            Grids1: {
                Title: '數字營銷',
                Dec: '啓動或改進您的數字營銷，包括社交媒體、搜索引擎、KOL ，將您的的業務精準推送給客戶！',
            },
            Grids2: {
                Title: '廣告',
                Dec: '針對多平臺廣告策略的綜合ADS解決方案，降低推廣成本，爲您創造高收益。',
            },
            Grids3: {
                Title: '優秀的工具',
                Dec: '使用我們優秀的工具開始您的高效運營，包括媒體賬戶管理（Facebook、Messenger、TikTok 等）、EDM、Landing Page、聊天機器人等。',
            },
            BottomTitle: '與我們一起輕鬆開展業務',
            BottomDec: '告訴我們您的業務需求，我們經驗豐富的專業營銷顧問將為您提供專屬營銷策略',
        },
        // index-block3
        Block3: {
            Title: '具有專業内容的社交媒體',
            Dec: '四分之三的消費者在購買產品或服務之前會諮詢社交媒體。 隨著社交媒體環境的快速變化，我們幫助客戶製作具有行業高轉化率的內容。 我們涵蓋的社交媒體行銷管道包括：',
            Grids1: {
                Title: 'Facebook廣告和管理',
                Dec: '通過我們的優化工具，自動化您的臉書廣告策略，提高ROI。'
            },
            Grids2: {
                Title: 'KOL分析',
                Dec: '確定適合推廣您品牌、產品和活動的合適KOL。'
            },
            Grids3: {
                Title: '自定義品牌頁面',
                Dec: '使用我們的網頁設計工具，只需幾分鐘即可輕鬆定制您的品牌頁面。'
            },
            Grids4: {
                Title: '視頻廣告',
                Dec: '豐富的行業視頻廣告素材，以及TikTok的多帳戶管理工具。'
            },
            Grids5: {
                Title: '競爭對手分析',
                Dec: '行銷數據的多元分析和同行業競爭對手的排名。'
            },
            Grids6: {
                Title: 'EDM',
                Dec: '通過我們的EDM直接聯系終端客戶並管理電子郵件。'
            },
        },
        // index-block4
        Block4: {
            Title: '500%+ ROI',
            Dec: '我們通過數據驅動策略實現高ROI的市場營銷，在您計畫與成倍新增的客戶打交道的同時完善您的營銷方案。 我們將負責微調和執行您的廣告研究、規劃、執行和評估。'
        },
        // index-block5
        Block5: {
            Title: '用我們的專業服務開始你的市場營銷。',
            Dec: '獲取您的獨家營銷計畫和定價，開始您的業務增長之旅。',
            Button: '聯係我們'
        },
        // Footer
        Footer: {
            ContactInformation: '聯係信息',
            Address: '地址',
            AddressValue: '香港九龍上海街438號同珍商業中心12樓1203室',
            Email: '電郵'
        }
    },
    // Contact
    Contact: {
        // 导航栏
        Nav: {
            Home: "首頁",
            Contact: '聯係',
        },
        ContactUs: '聯係我們',
        AddressValue: '香港九龍上海街438號同珍商業中心12樓1203室',
        Form: {
            FirstName: '名字',
            LastName: '姓',
            YourEmailID: '您的電子郵件',
            PhoneNumber: '手機號碼',
            Subject: '主題',
            Message: '消息',
            SendMessage: '發送消息'
        },
        // Footer
        Footer: {
            ContactInformation: '聯係信息',
            Address: '地址',
            AddressValue: '香港九龍上海街438號同珍商業中心12樓1203室',
            Email: '電郵'
        }
        
    }

}